import * as React from 'react';
import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
    Form,
    required,
    TextInput,
    useLogin,
    useNotify,
} from 'react-admin';
import RecaptchaCompoent from "../components/RecaptchaCompoent";
import {FieldValues} from "react-hook-form";
import {Image} from "@mui/icons-material";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(undefined);
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const sitekey = '6Le82RcqAAAAAH9tfKC-2imFqEPb1fL9b17eL8aD';
    const [errors, setErrors] = useState<FieldValues>({});

    useEffect(() => {
        document.title = "捷可印-部落格-管理者登入";
    }, []);

    const handlerVerify = (token: string) => {
        if (token) {
            setRecaptchaToken(token);
            setErrors({});
        }
    }

    const handlerExpire = () => {
        setRecaptchaToken(undefined);
    }

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(
            {
                username: auth.username,
                password: auth.password,
                reCAPTCHA: recaptchaToken,
            }, location.state ? (location.state).nextPathname : '/'
        ).catch((error: Error) => {
            setLoading(false);
            notify('登錄失敗，請檢查您的賬戶和密碼', {type: 'error'});
        });
    };

    const validateUserCreation = (values: FieldValues) =>{
        const errors: FieldValues = {};
        if (!values.username) {
            errors.username = '請輸入賬戶';
        }
        if (!values.password) {
            errors.password = '請輸入密碼';
        }
        if (!recaptchaToken) {
            errors.reCAPTCHA = '請驗證reCAPTCHA';
            setErrors(errors);
        }
        return errors;
    }

    return (
        <Form onSubmit={handleSubmit} noValidate validate={validateUserCreation}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: 'url(https://source.unsplash.com/featured/1600x900)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card sx={{ minWidth: 300, marginTop: '6em' }}>
                    <Box
                        sx={{
                            margin: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        {/*<Avatar sx={{ bgcolor: 'secondary.main' }}>*/}
                        {/*    <LockIcon />*/}
                        {/*</Avatar>*/}
                        <img src="/images/logo.png"  alt="捷可印"/>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <span>部落格-後台系統</span>
                    </Box>
                    <Box sx={{ padding: '0 1em 1em 1em' }}>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                autoFocus
                                source="username"
                                label="管理者賬戶"
                                disabled={loading}
                                validate={required()}
                             name="username"/>
                        </Box>
                        <Box sx={{ marginTop: '1em' }}>
                            <TextInput
                                source="password"
                                label="管理者密碼"
                                type="password"
                                disabled={loading}
                                validate={required()}
                             name="password"/>
                        </Box>
                        <Box sx={{ marginTop: '1em' }}>
                            <RecaptchaCompoent sitekey={sitekey} onVerify={handlerVerify} onExpire={handlerExpire} />
                            {errors.reCAPTCHA && <p
                                style={{color: '#d32f2f',fontFamily: 'Roboto ,Helvetica ,rial ,sans-serif',
                                fontWeight: 400,
                                fontSize: '0.75rem',
                                lineHeight: 1.66,
                                letterSpacing: '0.03333em',
                                textAlign: 'left',
                                marginTop: '4px',
                                marginRight: '14px',
                                marginBottom: 0,
                                marginLeft: '14px'}}>{errors.reCAPTCHA}</p>}
                        </Box>
                    </Box>
                    <CardActions sx={{ padding: '1em 1em 1em 1em' }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            fullWidth
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            登入
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

export default Login;

interface FormValues {
    username?: string;
    password?: string;
}
