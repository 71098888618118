import * as React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FieldProps } from "ra-ui-materialui/src/field/types";
import {
    Confirm, useDelete,
    useNotify,
    useRecordContext,
    useRedirect,
    useRefresh,
    useUpdate
} from "react-admin";
import {useState} from "react";
import {BlogItemOnListEnum} from "../components/BlogItemOnListField";
import StraightOutlinedIcon from '@mui/icons-material/StraightOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import TransferList from "../components/TransferList";

const BlogItemsRowActions = ({ source }: FieldProps) => {
    const record = useRecordContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [delOpen, setDelOpen] = useState(false);
    const [onListOpen, setOnListOpen] = useState(false);
    const [onList, setOnList] = useState<number>(0);
    const [openTransfer, setOpenTransfer] = useState(false);
    const refresh = useRefresh();
    const redirect = useRedirect();
    const  notify = useNotify();
    const [remove, { isPending: deleting }] = useDelete(
        'blogitems',
        { id: record?.id }
    );

    const [update, { isPending: onlisting}] = useUpdate(
        'blogitems/status',
        { id: record?.id, data: {itemId: record?.id, status: onList}, previousData: record }
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        redirect(`/blogitems/${record?.id}`)
        handleClose();
    };

    const handleOnList = (status: number) => {
        setOnList(status);
        setOnListOpen(true);
        handleClose();
    };


    const handleDelete = () => {
        setDelOpen(true);
        handleClose();
    };

    const redenderOnList = (status: number) => {
        switch (status) {
            case BlogItemOnListEnum.OFF_LINE:
                return '下線';
            case BlogItemOnListEnum.ONL_INE:
                return '上線';
            case BlogItemOnListEnum.PIN_TO_TOP:
                return '置頂';
            default:
                return 'Unknown';
        }
    }

    const handleDelClose = () => setDelOpen(false);
    const handleDelConfirm = () => {
        remove('blogitems', { id: record?.id }, {
            onSuccess: () => {
                refresh();
                setDelOpen(false);
            },
            onError: (error) => {
                setDelOpen(false);
            },
        });
    };

    const handleOnListClose = () => setOnListOpen(false);
    const handleOnListConfirm = () => {
        update('blogitems/status', { id: record?.id,data: {itemId: record?.id, status: onList},previousData: record }, {
            onSuccess: () => {
                refresh();
                setOnListOpen(false);
            },
            onError: (error: Error) => {
                setOnListOpen(false);
                notify(error.message, { type: 'error' });
            },
        });
    };

    const handlerReference = () => {
        setOpenTransfer(true);
        handleClose();
    }
    const handlerSaveReference = (reference: string[]) => {
        update('blogitems', { id: record?.id,data: { ...record, reference},previousData: record }, {
            onSuccess: () => {
                refresh();
                setOpenTransfer(false);
                notify('Upload Element Success', { type: 'success' });
            },
            onError: (error: Error) => {
                setOpenTransfer(false);
                notify(error.message, { type: 'error' });
            },
        });
    };

    return (
        <>
            <IconButton aria-label="more" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}><EditOutlinedIcon/>編輯</MenuItem>
                <MenuItem disabled={record?.onList === 1} onClick={()=>{handleOnList(1)}}><StraightOutlinedIcon/>上架</MenuItem>
                <MenuItem disabled={record?.onList === 0} onClick={()=>{handleOnList(0)}}><VerticalAlignBottomOutlinedIcon/>下架</MenuItem>
                <MenuItem disabled={record?.onList === 100} onClick={()=>{handleOnList(100)}}><VerticalAlignTopOutlinedIcon/>上架且置頂</MenuItem>
                <MenuItem onClick={handlerReference}><MoveUpOutlinedIcon/>關聯文章</MenuItem>
                <MenuItem onClick={handleDelete} disabled={record?.onList > 0}><DeleteOutlineOutlinedIcon/>删除</MenuItem>
            </Menu>
            <Confirm
                isOpen={delOpen}
                loading={deleting}
                title={`删除 ${record?.id}`}
                content="您確定想要刪除該投稿文章?"
                onConfirm={handleDelConfirm}
                onClose={handleDelClose}
            />
            <Confirm
                isOpen={onListOpen}
                loading={onlisting}
                title={`審核 ${record?.id}`}
                content={`您確定想要將該投稿文章: ${redenderOnList(onList)}?`}
                onConfirm={handleOnListConfirm}
                onClose={handleOnListClose}
            />
            <TransferList
                isOpen={openTransfer}
                onClose={() => setOpenTransfer(false)}
                itemId={record?.id as string}
                category={record?.category}
                reference={record?.reference}
                onSave={(ids) => handlerSaveReference(ids)}
                title={`設定「${record?.title}」關聯文章`}
            />
        </>
    );
};

export default BlogItemsRowActions;
