import React from 'react';
import {InputProps, useInput} from 'react-admin';
import {TextField} from '@mui/material';
import {TipTapEditorWrapper} from "tiptap-editor";

export type TipTapEditorInputProps = {
    defaultTxtContent?: string;
    onSrcContent?: (srcContent: string) => void;
} & InputProps;

const TipTapEditorInput = (props: TipTapEditorInputProps) => {
    const [isFullScreen, setIsFullScreen] = React.useState(false);
    const {defaultTxtContent, onSrcContent} = props;
    const [isOneTime, setIsOneTime] = React.useState(true);
    // 使用 useInput 來獲取控制字段的各種方法和屬性
    const {
        field,        // field 包含了 value 和 onChange 方法
        fieldState,   // fieldState 包含了 isTouched, isDirty 等狀態
        formState,    // formState 包含整個表單的狀態
        id,
    } = useInput(props);

    // 處理手動設置值的函數
    const handleSetTextContentValue = (content: string) => {
        if(isOneTime){
            setIsOneTime(false);
        } else if (content !== field.value && content !== defaultTxtContent) {
            field.onChange(content); // 使用 onChange 方法設置新值
        } else {
            field.onChange("");
        }
    };

    return (
        <div>
            <TextField
                {...field} // 繫結輸入的 value 和 onChange
                label={props.label}       // 傳遞 label
                error={!!fieldState.error} // 顯示錯誤
                helperText={fieldState.error?.message} // 顯示錯誤信息
                fullWidth
                defaultValue={defaultTxtContent}
                style={{display: 'none'}}
            />
            <div className="flex w-full">
                <span className={`w-[80px] ${fieldState.error ? 'text-red-500' : 'text-gray-500'} self-center`}>{props.label}*</span>
                <div className={`${
                    isFullScreen
                        ? 'fixed top-10 left-0 w-full h-full flex justify-center bg-white z-50'
                        : 'grow w-auto'
                }`}>
                    <TipTapEditorWrapper key={defaultTxtContent} defaultContent={defaultTxtContent} model={'full'} maxWidth={'1280px'}
                                         onChangeContent={(content) => handleSetTextContentValue(content)}
                                         getTipTapEditor={(editor) => editor && editor.getJSON() && onSrcContent && onSrcContent(JSON.stringify(editor.getJSON()))}
                                         onFullscreen={(full) => setIsFullScreen(full)}/>
                </div>
            </div>
            {(fieldState.error) && <span className={'text-red-500 text-xs'}>Required</span>}
        </div>
    );
};

export default TipTapEditorInput;
