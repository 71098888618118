import { AuthProvider } from 'react-admin';

const authProvider: AuthProvider = {
    login: ({ username, password, reCAPTCHA }) => {
        const request = new Request('/user/login', {
            method: 'POST',
            body: JSON.stringify({ username, password, reCAPTCHA }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if(auth && auth.status === 200){
                    localStorage.setItem('user', JSON.stringify(auth.data));
                    return Promise.resolve(auth.data);
                } else {
                    return Promise.reject(auth.message);
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    },
    logout: () => {
        localStorage.removeItem('user');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const request = new Request('/user/check', {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    localStorage.removeItem('user');
                    return Promise.reject(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if(auth && auth.status !== 200){
                    return Promise.reject(auth.message);
                }
                return Promise.resolve();
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    },
    getPermissions: () => {
        const user = localStorage.getItem('user');
        return user ? Promise.resolve(JSON.parse(user).authRole) : Promise.reject();
    },
    getIdentity: () => {
        const user = localStorage.getItem('user');
        const item = user && JSON.parse(user);
        return user ? Promise.resolve({
            ...item,
            id: item.adminId,
            fullName: item.adminId,
        }) : Promise.reject();
    },
};

export default authProvider;
