import {
    BooleanInput,
    Create, email, ListButton, regex, required,
    SaveButton, SimpleForm,
    TextInput,
    Toolbar, useCreateContext,
} from "react-admin";
import * as React from "react";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Grid} from "@mui/material";
import {useParams} from "react-router";
import {useEffect} from "react";


export const CreateToolbar = ({resource}: {resource: string}) => {
    const { isPending } = useCreateContext();
    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton label="Save"/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton disabled={isPending} size={"medium"} resource={resource} label="GO BACK" variant={"outlined"}  icon={<UndoIcon/>}/>
                </Grid>
            </Grid>
        </Toolbar>
    );
}


const MCampaignLogCreate = () => {
    const {id} = useParams();
    const [logId, setLogId] = React.useState<string>();

    const validateURL = (value: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // 协议
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // 域名
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // 或 IP (v4) 地址
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // 端口和路径
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // 查询字符串
            '(\\#[-a-z\\d_]*)?$','i'); // hash标签
        if (!pattern.test(value)) {
            return '請輸入有效的鏈接地址';
        }
        return undefined;
    };

    const generateTenDigitNumber = () => {
        const min = 1000000000;
        const max = 9999999999;
        return (Math.floor(Math.random() * (max - min + 1)) + min).toString();
    }

    useEffect(() => {
        setLogId(generateTenDigitNumber());
    }, []);

    if (!id) { return <p>ERROR</p>; }

    return (
        <Card sx={{mt: 2}}>
            <CardContent>
                <Create resource={"mcampaignlogs"} redirect={`/mcampaigns/com.ilinke.jcolor:cms_console_war:war:1.0/mcampaignlogs`}>
                    <SimpleForm toolbar={<CreateToolbar resource={`/mcampaigns/com.ilinke.jcolor:cms_console_war:war:1.0/mcampaignlogs`} />} warnWhenUnsavedChanges >
                        <TextInput source="campaignId" label="活動編號" validate={required()} defaultValue={id} readOnly name="campaignId"/>
                        <TextInput source="logId" label="日誌ID" defaultValue={logId} name="logId" />
                        <TextInput source="realName" label="留言者真實姓名" validate={required()} name="realName"/>
                        <TextInput source="email" label="留言者email" validate={[required(),email()]} name="email"/>
                        <TextInput source="phoneNumber" label="留言者電話" validate={[required(),regex(/^09[0-9]{8}$/,'電話验证错误')]} name="phoneNumber"/>
                        <TextInput source="externalLink" label="活動網址" validate={[required(),validateURL]} name="externalLink"/>
                        <BooleanInput source="toTop" label="留言置頂" validate={required()} name="toTop"/>
                        <TextInput source="leaveMessage" multiline resettable rows={5} label="用戶留言" validate={required()} name="leaveMessage"/>
                    </SimpleForm>
                </Create>
            </CardContent>
        </Card>
    );
};

export default MCampaignLogCreate;