import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';

const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <>
            <img src="./images/logo.png" alt="捷可印"/>
        </>
    );
};

export default Logo;
