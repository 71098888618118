import {
    CreateParams,
    DataProvider,
    DeleteManyParams,
    DeleteParams,
    fetchUtils,
    GetListParams,
    UpdateManyParams,
    UpdateParams
} from "react-admin";
import {BlogCtrbItem, BlogItem, BlogTag, MCampaign, MCampaignLog, PageResponse, RESTFullResponse, User} from "../types";

const httpClient = fetchUtils.fetchJson;

const removeObjectProperty = function (obj: Record<string, unknown>, filter: Record<string, unknown>, keyToRemove: string): Record<string, unknown> {
    const { [keyToRemove]: _, ...rest } = obj;
    return rest;
}

const formatDate = (dateStr: string) => {
    return dateStr.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
}

const customDataProvider:DataProvider = {
    //@ts-ignore
    getList: (resource: string, params: GetListParams) => {
        switch (resource) {
            case 'blogctrbitems':
                const blogCtrbItemsPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                const blogCtrbItemsFilter  = params.filter?.status !== 1 ? {...params.filter} : removeObjectProperty(params.filter, params.filter as Record<string, unknown>, 'status');
                const blogCtrbItemsParam = { ...blogCtrbItemsFilter, ...blogCtrbItemsPage };
                const blogCtrbItemsUrlSearch = new URLSearchParams(blogCtrbItemsParam);
                const blogCtrbItemsUrlParam =`/api/${resource}?${blogCtrbItemsUrlSearch.toString()}`;
                return httpClient(blogCtrbItemsUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<BlogCtrbItem>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    const data = json.data.records.map(item => ({
                        id: item.itemId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
           case 'blogitems':
                const blogItemsPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                const blogItemsFilter  = params.filter?.onList !== -1 ? {...params.filter} : removeObjectProperty(params.filter, params.filter as Record<string, unknown>, 'onList');
                const blogItemsParam = { ...blogItemsFilter, ...blogItemsPage };
                const blogItemsUrlSearch = new URLSearchParams(blogItemsParam);
                const blogItemsUrlParam =`/api/${resource}?${blogItemsUrlSearch.toString()}`;
                return httpClient(blogItemsUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<BlogItem>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    const data = json.data.records.map(item => ({
                        id: item.itemId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'blogtags':
                const blogTagsPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                const blogTagsFilter = params.filter.type !== -1  ? {...params.filter} : removeObjectProperty(params.filter, params.filter as Record<string, unknown>, 'type');
                const blogTagsParam = { ...blogTagsFilter, ...blogTagsPage };
                const blogTagsUrlSearch = new URLSearchParams(blogTagsParam);
                const blogTagsUrlParam = `/api/${resource}?${blogTagsUrlSearch.toString()}`;
                return httpClient(blogTagsUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<BlogTag>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    const data = json.data.records.map(item => ({
                        id: item.tagId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                const mcampaignPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                const mcampaignFilter = params.filter && {...params.filter};
                const mcampaignParam = { ...mcampaignFilter, ...mcampaignPage };
                const mcampaignUrlSearch = new URLSearchParams(mcampaignParam);
                const mcampaignUrlParam = `/api/${resource}?${mcampaignUrlSearch.toString()}`;
                return httpClient(mcampaignUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<MCampaign>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    const data = json.data.records.map(item => ({
                        id: item.campaignId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                const mcampaignLogPage = {pageNum: params.pagination?.page, pageSize: params.pagination?.perPage};
                const mcampaignLogFilter = params.filter && {...params.filter};
                const mcampaignLogParam = { ...mcampaignLogFilter, ...mcampaignLogPage };
                const mcampaignLogUrlSearch = new URLSearchParams(mcampaignLogParam);
                const mcampaignLogUrlParam = `/api/${resource}?${mcampaignLogUrlSearch.toString()}`;
                return httpClient(mcampaignLogUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<PageResponse<MCampaignLog>>}) => {
                    if (!json.data) {
                        throw new Error("Response data does not contain 'items' key");
                    }
                    if (!Array.isArray(json.data.records)) {
                        throw new Error("Response items must be an array");
                    }
                    // 将 items 转换为包含 id 的数据结构
                    const data = json.data.records.map(item => ({
                        id: item.logId,
                        ...item
                    }));

                    return {
                        data,
                        total: json.data.total,
                    };
                }).catch(error => Promise.reject(error));
            default:
                return Promise.reject(`Unknown resource: ${resource}`);
        }
    },
    //@ts-ignore
    getOne: (resource: string, params: any) => {
        switch (resource) {
            case 'blogtags':
                const blogTagUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogTagUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogTag>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: json.data.tagId,
                            ...json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'blogctrbitems':
                const blogCtrbItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogCtrbItemUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogCtrbItem>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    const data = json.data.videoLink ? {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                        videoFile: {
                            src: json.data.videoLink,
                            title: 'videoFile'
                        }
                    } : {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                    };
                    return {
                        data: data
                    };
                }).catch(error => Promise.reject(error));
            case 'blogitems':
                const blogItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogItemUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogItem>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    const data = json.data.videoLink ? {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                        videoFile: {
                            src: json.data.videoLink,
                            title: 'videoFile'
                        }
                    } : {
                        ...json.data,
                        id: json.data.itemId,
                        coverImgFile: {
                            src: json.data.coverImgLink,
                            title: 'coverImgFile'
                        },
                    };
                    return {
                        data: data
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns/check':
                const mcampaignCheckUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(mcampaignCheckUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.id,
                            data:json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                const mcampaignUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(mcampaignUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaign>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    const data = {
                        ...json.data,
                        id: json.data.campaignId,
                        coverImageFile: {
                            src: json.data.coverImage,
                            title: 'coverImgFile'
                        },
                        startDate: formatDate(json.data.startDate.toString()),
                        endDate: formatDate(json.data.endDate.toString())
                    };
                    return {
                        data: data
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                const mcampaignLogUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(mcampaignLogUrlParam,{
                    headers: new Headers({
                        'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                    }),
                }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaignLog>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Get operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: {
                            id: json.data.logId,
                            ...json.data
                        }
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    //@ts-ignore
    delete: (resource: string, params: DeleteParams) => {
        switch (resource){
            case 'blogctrbitems':
                const blogCtrbItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogCtrbItemUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: json.data
                    };
                }).catch(error => Promise.reject(error));
            case 'blogitems':
                const blogItemUrlParam = `/api/${resource}/${params.id}`;
                return httpClient(blogItemUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.id}`);
                    }
                    return {
                        data: json.data
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    //@ts-ignore
    deleteMany: (resource: string, params: DeleteManyParams) => {
        switch (resource){
            case 'blogtags':
                const blogTagsUrlParam = `/api/${resource}?tagIds=${params.ids}`;
                return httpClient(blogTagsUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.ids}`);
                    }
                    return {
                        data: params.ids
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                const mcampaignLogsUrlParam = `/api/${resource}?logIds=${params.ids}`;
                return httpClient(mcampaignLogsUrlParam,
                    {
                        method: 'DELETE',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Delete operation failed for ${resource} with id ${params.ids}`);
                    }
                    return {
                        data: params.ids
                    };
                }).catch(error => Promise.reject(error));

        }
    },
    //@ts-ignore
    update: (resource: string, params: UpdateParams) => {
        switch (resource){
            case 'blogctrbitems/review':
                const blogCtrbItemReviewUrlParam = `/api/${resource}`;
                return httpClient(blogCtrbItemReviewUrlParam,
                    {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with id ${params.id}`);
                    }
                    params.previousData.onList = params.data.status;
                    return {
                        data: params.previousData
                    };
                }).catch(error => Promise.reject(error));
            case 'blogitems/status':
                const blogItemStateUrlSearch = new URLSearchParams(params.data);
                const blogItemStatusUrlParam = `/api/${resource}?${blogItemStateUrlSearch.toString()}`;
                return httpClient(blogItemStatusUrlParam,
                    {
                        method: 'PUT',
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with id ${params.id}`);
                    }
                    params.previousData.onList = params.data.status;
                    return {
                        data: params.previousData
                    };
                }).catch(error => Promise.reject(error));
            case 'blogtags':
                const blogItemsData = {...params.data}
                const blogItemsUrl = `/api/${resource}`;
                return httpClient(blogItemsUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(blogItemsData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: params.data
                    };
                }).catch(error => Promise.reject(error));
            case 'blogctrbitems':
                const blogCtrbItemData = {...params.data}
                const blogCtrbItemFormData = new FormData();
                Object.keys(blogCtrbItemData).forEach(key => {
                    blogCtrbItemFormData.append(key, blogCtrbItemData[key]);
                });
                const blogCtrbItemUrl = `/api/${resource}/edit`;
                return httpClient(blogCtrbItemUrl,
                    {
                        method: 'POST',
                        body: blogCtrbItemFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.itemId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case "blogitems":
                const blogItemData = {...params.data}
                const blogItemFormData = new FormData();
                Object.keys(blogItemData).forEach(key => {
                    blogItemFormData.append(key, blogItemData[key]);
                });
                const blogItemUrl = `/api/${resource}/edit`;
                return httpClient(blogItemUrl,
                    {
                        method: 'POST',
                        body: blogItemFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.itemId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                const mcampaignData = {...params.data}
                const mcampaignFormData = new FormData();
                Object.keys(mcampaignData).forEach(key => {
                    mcampaignFormData.append(key, mcampaignData[key]);
                });
                const mcampaignUrl = `/api/${resource}/edit`;
                return httpClient(mcampaignUrl,
                    {
                        method: 'POST',
                        body: mcampaignFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.campaignId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                const mcampaignLogData = {...params.data}
                const mcampaignLogUrl = `/api/${resource}`;
                return httpClient(mcampaignLogUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(mcampaignLogData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.id}`);
                    }
                    return {
                        data: {
                            id: params.data.logId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    //@ts-ignore
    updateMany: (resource: string, params: UpdateManyParams) => {
        switch (resource){
            case 'blogtags/type':
                const blogTagsData = {...params.data}
                const blogTagsTypeUrl = `/api/${resource}`;
                return httpClient(blogTagsTypeUrl,
                    {
                        method: 'PUT',
                        body: JSON.stringify(blogTagsData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<boolean>}) => {
                    if (json.status !== 200 || !json.data) {
                        throw new Error(`Update operation failed for ${resource} with ids ${params.ids}`);
                    }
                    return {
                        data: params.ids
                    };
                }).catch(error => Promise.reject(error));
        }
    },
    // @ts-ignore
    create: (resource: string, params: CreateParams) => {
        switch (resource){
            case 'blogtags':
                const blogTagsData = {...params.data}
                const blogTagsCreateUrl = `/api/${resource}`;
                return httpClient(blogTagsCreateUrl,
                    {
                        method: 'POST',
                        body: JSON.stringify(blogTagsData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogTag>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.tagId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'blogctrbitems':
                const blogCtrbItemData = {...params.data}
                const blogCtrbItemFormData = new FormData();
                Object.keys(blogCtrbItemData).forEach(key => {
                    blogCtrbItemFormData.append(key, blogCtrbItemData[key]);
                });
                const blogCtrbItemCreateUrl = `/api/${resource}`;
                return httpClient(blogCtrbItemCreateUrl,
                    {
                        method: 'POST',
                        body: blogCtrbItemFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<BlogCtrbItem>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.itemId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaigns':
                const mcampaignData = {...params.data}
                const mcampaignFormData = new FormData();
                Object.keys(mcampaignData).forEach(key => {
                    mcampaignFormData.append(key, mcampaignData[key]);
                });
                const mcampaignCreateUrl = `/api/${resource}`;
                return httpClient(mcampaignCreateUrl,
                    {
                        method: 'POST',
                        body: mcampaignFormData,
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaign>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.campaignId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
            case 'mcampaignlogs':
                const mcampaignLogData = {...params.data}
                const mcampaignLogCreateUrl = `/api/${resource}`;
                return httpClient(mcampaignLogCreateUrl,
                    {
                        method: 'POST',
                        body: JSON.stringify(mcampaignLogData),
                        headers: new Headers({
                            'Authorization': `Bearer ${localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string)?.token : ''}`,
                        }),
                    }).then(({ headers, json } : {headers: Headers, json: RESTFullResponse<MCampaignLog>}) => {
                    if (json.status!== 200 || !json.data) {
                        throw new Error(`Create operation failed for ${resource}`);
                    }
                    return {
                        data: {
                            id: params.data.logId,
                            ...params.data
                        }
                    };
                }).catch(error => Promise.reject(error));
        }
    }
}

export default customDataProvider;
